@import "../../styles/mixins";
@import "../../styles/variables";

.nav {
  border-top: 2px $white solid;
  margin: 0 3rem 0 2rem;
}

.list {
  text-align: left;
  list-style: none;
  padding: 1rem 0;
  margin: 0;
}

.label {
  padding-top: 2rem;
  font-size: 16px;
  color: $white;
  text-align: left;
  font-family: $font-family-light;
  display: block;
}

.list a {
  font-size: 19px;
  color: $white;
  font-family: $font-family-light;
  padding: 0.3rem 0 0.3rem;
  display: block;
}