$fontFamily-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$fontFamily-headings: $fontFamily-base;
$fontSize-default: 16px;
$fontSmoothing-default: true;

$font-family-light: "PostGrotesk-Light", Helvetica, Arial, sans-serif,;
$font-family-medium: "PostGrotesk-Medium", Helvetica, Arial, sans-serif,;
$font-family-bold: "PostGrotesk-Bold", Helvetica, Arial, sans-serif,;
$font-family-regular: "", Helvetica, Arial, sans-serif,;
$font-family-akkurat: "akkurat-mono", Helvetica, Arial, sans-serif;

// Screens
$tablet-up: 768px;
$laptop-up: 992px;
$desktop-up: 1025px;
$desktop-med-up: 1200px;
$desktop-large-up: 1400px;
$desktop-xlarge-up: 1560px;

$inner-container-left: 5rem;

// ------ colors ------ //

$black: #000;
$black-near: #1C1D1E;
$blue-inscape: #6090B3;
$blue-dark: #15293E;
$blue-bright: #2462F8;
$blue-light: #1BCEB2;
$blue-lighter: #F4F8FB;
$gray: #565a5C;
$gray-dark: #83878A;
$gray-darker: #222;
$gray-darkest: #1a1a1a;
$gray-light: #ccc;
$gray-lighter: #fefefe;
$gray-lightest: #f3f3f3;
$red: #ff0000;
$white: #fff;

// ------ defaults ------ //
$fontColor-base:     $gray;
$fontColor-links:    $gray;
$fontColor-headings: $black-near;
$pageBg:             $gray-lightest;