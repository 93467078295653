@import "../../styles/variables";

.app {
  text-align: center;
  min-height: 100vh;
}

.app_header {

}

.app_logo {
  height: 40vmin;
}