@import "../../styles/mixins";
@import "../../styles/variables";

.dropdown {
  display: inline-block;
  color: #fff;
  margin-left: 2rem;
  position: relative;
  text-align: left;

  &:before {
    top: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 10px solid #fff;
    content: "";
    height: 0;
    position: absolute;
    right: 15px;
    transition: 0.45s;
    width: 0;
    z-index: 2;

    @include tablet {
      top: .5rem;
    }
  }

  &.active {
    &:before {
      transform: rotate(180deg);
    }
  }
}

.label {
  button {
    font-family: $font-family-medium;
    background: transparent;
    border-color: #fff;
    border-radius: 0;
    border-style: solid;
    border-width: 0 0 2px 0;
    color: #fff;
    display: inline-block;
    font-size: 16px;
    line-height: 28px;
    padding: 5px 0 0 1rem;
    width: 175px;
    text-align: left;

    @include tablet {
      width: 180px;
    }
  }
}

.active_link span {
  color: $blue-dark;
}

.list {
  position: relative;

  ul {
    margin: 0;
    padding: 0;
    min-width: 215px;
    background: $blue-bright;
    left: -1.2rem;
    list-style: none;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: -49px;
    transition: 0.45s;
    width: auto;
    z-index: 1;
    padding-bottom: 10px;
    padding-top: 10px;

    @include tablet {
      min-width: 220px;
    }

    &.active {
      opacity: 1;
      pointer-events: inherit;

      &:before {
        transform: rotate(180deg);
      }
    }

    a {
      font-family: $font-family-medium;
      border-bottom: none;
      text-align: left;
      color: #fff;
      display: block;
      font-size: 16px;
      line-height: 28px;
      padding: 4px 20px;
      transition: color 250ms ease, opacity 100ms ease-in-out, transform 250ms;

      &:hover {
        color: $blue-dark !important;
      }
    }
  }
}

.dropdown_mobile {
  margin-left: 0;
}

.dropdown_mobile:before {
  top: 0.45rem;
  right: 3rem;
}

.dropdown_mobile .list ul {
  left: 0;
  width: 100%;
  visibility: hidden;
}

.dropdown_mobile .list ul.active {
  left: 0;
  visibility: visible;
}

.dropdown_mobile button {
  width: 300px;
}