
// -- Media Queries -- //
@mixin mobile {
  @media (max-width: $tablet-up - 1px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet-up) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: $laptop-up) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop-up) {
    @content;
  }
}

@mixin desktop-med {
  @media (min-width: $desktop-med-up) {
    @content;
  }
}

@mixin desktop-large {
  @media (min-width: $desktop-large-up) {
    @content;
  }
}

@mixin desktop-xlarge {
  @media (min-width: $desktop-xlarge-up) {
    @content;
  }
}