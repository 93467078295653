@import "../../styles/variables";
@import "../../styles/mixins";

.landing {
  @include tablet {
    margin: 3rem;
  }
  text-align: center;
}

.container {
  padding: 1rem;
  margin: 3rem auto;
  color: $white;
  text-align: center;

  @include tablet {
    padding: 3rem;
  }
  @include desktop {
    max-width: 700px;
  }
}

.title {
  font-family: $font-family-bold;
  margin: 1rem auto;
  font-size: 2rem;
  max-width: 16rem;

  @include tablet {
    margin: 2rem auto;
    font-size: 3rem;
    max-width: 24rem;
  }
}

.subtitle {
  font-family: $font-family-light;
}

.list {
  overflow: hidden;
  text-align: center;
  margin: 2rem auto 0 auto;

  @include tablet {
    max-width: 20rem;
    margin: 4rem auto 0 auto;
  }
}

.list li {
  font-family: $font-family-medium;
  display: block;
}

.list li a {
  background: $blue-bright;
  font-family: $font-family-medium;
  color: $white;
  font-size: 1.2rem;
  padding: 1rem 0;
  margin: 0 1rem 1rem 1rem;
  display: block;
  position: relative;

  @include tablet {
    padding: 1.5rem 0;
    font-size: 1.4rem;
  }

  @include desktop {
    &:before {
      z-index: 5;
      content: "";
      position: absolute;
      width: 100%;
      height: 0;
      bottom: 0;
      left: 0;
      background-color: $blue-light;
      transition: all 200ms ease-in 0s;
    }
  }

  &:hover {
    color: $white;

    @include desktop {
      &:before {
        height: 100%;
      }
    }
  }

  span {
    position: relative;
    z-index: 10;
  }
}