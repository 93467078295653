@import 'fonts';
@import 'variables';

body {
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  background: $white;
  color: $fontColor-base;
  font-family: $font-family-light;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;

  &.landing {
    background: $blue-dark;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-bold;
}

a {
  color: $blue-bright;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.outer-container {
  margin: 0 auto;
  max-width: 1420px;
  position: relative;
}

.row.mb-4 {
  margin-bottom: 3rem !important;
}