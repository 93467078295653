@import '../../styles/variables';
@import '../../styles/mixins';

.menu {
  background: $blue-dark;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 5rem 0 0;
  position: fixed;
  right: 0;
  top: 0;
  transform: translate3d(100vw, 0, 0);
  transition: all 250ms ease;
  z-index: 3;
  max-width: 100vw;

  @include tablet {
    display: none;
  }
}

.menu_active {
  transform: translate3d(0, 0, 0);
}