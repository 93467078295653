@import '../../styles/variables';
@import '../../styles/mixins';

.icon {
  border: 0;
  outline: none !important;
  position: relative;
  right: 0.5rem;
  background: none;
  float: right;
  z-index: 4;

  @include tablet {
    display: none;
  }
}

.closed, .opened {
  position: absolute;
  top: 0;
}

.closed {
  transition: 250ms ease all;
}

.opened {
  opacity: 0;
  transition: 250ms ease all;
  right: -20px;
}

.icon_active .closed {
  transform: translateX(50px);
  opacity: 0;
}

.icon_active .opened {
  transform: translateX(0px);
  opacity: 1;
}