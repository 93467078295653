@import '../../styles/variables';
@import '../../styles/mixins';

.page_nav {
  padding-bottom: 1rem;
  @include desktop {
    padding-top: 4rem;
    padding-bottom: 3rem;
    z-index: 2;
    position: relative;
  }
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
  @include desktop-large {
    min-width: 1200px;
  }

  @include mobile {
    padding-left: 1rem;
  }
}

.list li {
  display: inline-block;
  padding-right: 2rem;
}

.list li a {
  display: block;
  padding: 2rem 0 1.5rem;
  font-size: 1.25rem;
  color: $white;
  position: relative;

  @include mobile {
    font-size: 22px;
    font-family: $font-family-bold;
  }

  @include desktop {
    min-width: 10rem;
  }

  &:hover {
    &:before {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: $white;
    opacity:0;
    transform: translateY(5px);
    transition: all 200ms ease-in-out 0s;
  }
}

.list li a.active {
  color: $white;

  &:before {
    transform: translateY(0);
    opacity: 1;
  }
}

.page_nav_mobile .list li {
  display: block;
}

.page_nav_mobile .list li a{
  padding: 1rem 0 1rem;
  display: block;

  &.active {
    color: $blue-light;
    &:before {
      content: none;
    }
  }
}

.page_nav_container {
  padding-right: 0;
  @include desktop-large {
    padding-left: $inner-container-left;
  }
}