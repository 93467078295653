@font-face {
  font-family: "PostGrotesk-Light";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/PostGrotesk/PostGrotesk-Light.eot");
  src: url("../fonts/PostGrotesk/PostGrotesk-Light.woff2") format('woff2'),
  url("../fonts/PostGrotesk/PostGrotesk-Light.woff") format("woff"),
}

@font-face {
  font-family: "PostGrotesk-Medium";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/PostGrotesk/PostGrotesk-Medium.eot");
  src: url("../fonts/PostGrotesk/PostGrotesk-Medium.woff2") format('woff2'),
  url("../fonts/PostGrotesk/PostGrotesk-Medium.woff") format("woff"),
}

@font-face {
  font-family: "PostGrotesk-Bold";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/PostGrotesk/PostGrotesk-Bold.eot");
  src: url("../fonts/PostGrotesk/PostGrotesk-Bold.woff2") format('woff2'),
  url("../fonts/PostGrotesk/PostGrotesk-Bold.woff") format("woff"),
}

@font-face {
  font-family: "akkurat-mono";
  src: url("../fonts/AkkuratMono/lineto-akkuratmono-regular.eot");
  src: url("../fonts/AkkuratMono/lineto-akkuratmono-regular.eot?#iefix") format("embedded-opentype"),
  url("../fonts/AkkuratMono/lineto-akkuratmono-regular.woff2") format("woff2"),
  url("../fonts/AkkuratMono/lineto-akkuratmono-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}