@import '../../styles/variables';
@import '../../styles/mixins';

.container {
  overflow-x: hidden;
  overflow-y: auto;
  @include desktop {
    margin-top: -7rem;
  }

}

.inner {
  background: $blue-bright;
  color: $white;
  position: relative;
  padding: 2rem 0 1rem 0;

  @include desktop {
    padding: 7rem 0 0 0;
    background: transparent;
    &:before {
      z-index: 1;
      background: $blue-bright;
      content: " ";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    &:after {
      z-index: 1;
      background: $blue-bright;
      content: " ";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
    }
  }

  @include desktop-large {
    &:before {
      left: 6rem;
    }
  }
}

.title {
  font-family: $font-family-bold;
  text-align: left;
  font-size: 2rem;
  margin-bottom: 3rem;

  @include desktop {
    margin-bottom: 0;
    z-index: 2;
    position: relative;
    font-size: 45px;
    line-height: 47px;
    float: left;
    max-width: 45%;
  }
  @include desktop-large {
    max-width: 550px;
  }
}

.body {
  text-align: left;
  font-size: 1.2rem;
  margin-bottom: 3rem;
  @include tablet {
    font-size: 21px;
    line-height: 28px;
  }

  @include desktop {
    margin-bottom: 0;
    z-index: 2;
    position: relative;
    float: right;
    text-align: left;
    max-width: 45%;
  }
  @include desktop-large {
    margin-right: -8rem;
    max-width: 550px;
  }
}

.inner_container {
  @include desktop-large {
    padding-left: $inner-container-left;
  }
}