@import '../../styles/variables';
@import '../../styles/mixins';

.header {
  background: $blue-dark;
  color: $white;
  min-height: 6rem;
  padding: 1.2rem 0.5rem 0 0.5rem;

  @include tablet {
    padding: 2rem 2rem 0 0;
  }
  @include desktop {
    overflow: hidden;
  }
}

.container {
  @include laptop {
    max-width: 960px;
  }
  @include desktop-med {
    max-width: 1140px;
  }
  @include desktop-large {
    max-width: 100vw;
    padding: 0;
  }
}

.landing .header {
  padding: 2rem 2rem 0 0;
}

.logo {
  position: relative;
  z-index: 4;
  width: 148px;
  height: 28px;
  display: inline-block;

  @include tablet {
    left: 15px;
  }
  @include desktop {
    position: absolute;
    top: 1.5rem;
    left: 0;
  }

  @include desktop-large {
    left: 7rem;
  }
}

.page_nav {
  padding-bottom: 2rem;
  @include mobile {
    display: none;
  }
  @include desktop {
    padding-bottom: 14rem;
  }
}

.nav_link {
  font-family: $font-family-medium;
  color: $white;
  display: inline-block;
  position: relative;

  @include desktop {
    min-height: 2rem;
    margin-bottom: 1rem;
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: $blue-light;
    opacity: 0;
    transform: translateY(5px);
    transition: all 200ms ease-in-out 0s;

  }

  &:hover {
    color: $blue-light;

    &:before {
      opacity: 1;
      transform: translateY(-9px);
    }

    .right_arrow {
      transform: translateX(10px);
      svg, path, polygon {
        stroke: $blue-light !important;
        fill: $blue-light !important;
      }

      path {
        &.hover {
          display: block;
        }
      }
    }
  }

  .right_arrow {
    margin-left: .5rem;
    transition: 500ms ease-out all !important;
  }
}