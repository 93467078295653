@import '../../styles/variables';
@import '../../styles/mixins';

.page {
  background: $white;
  padding: 100px 2rem;
  text-align: left;
}

.page_content {
  font-size: 1.2rem;
  line-height: 1.8rem;
}

.page_content p {
  margin-bottom: 2rem;
}

.page_content hr {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.page_content h1, .page_content h2, .page_content h3, .page_content h4, .page_content h5, .page_content h6 {
  color: $blue-dark;
}

.page_content h1, .page_content h2, .page_content h3 {
  margin-bottom: 3rem;
}

.page_content h5 {
  font-family: $font-family-light;
}

.page_container {
  @include desktop-large {
    padding-left: $inner-container-left;
  }
}